import React from 'react'
import styled from 'styled-components'
import { WideContainer } from 'src/features/layout'

const FooterContainer = styled.footer`
  margin-top: 30px;
`

const Footer = () => (
  <WideContainer>
    <FooterContainer>© {new Date().getFullYear()}</FooterContainer>
  </WideContainer>
)

export default Footer
