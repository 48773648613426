import React from 'react'
import styled from 'styled-components'
import { FiShoppingCart } from 'react-icons/fi'
import { Link } from 'gatsby'
import { useCartItemList } from '@foundations-of-grace-pub/checkout'

const Container = styled.div`
  position: relative;
`

const CartIcon = styled(FiShoppingCart)`
  font-size: 40px;
  color: rgba(73, 73, 73);
`

const Number = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15px;
  top: 3px;
  background: #cc5501;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 8px;
  font-size: 13px;
`

const CartButton = () => {
  const num = useCartItemList(s => s.getItemCount())

  return (
    <Link to="/cart">
      <Container>
        <CartIcon />
        {num > 0 && <Number>{num}</Number>}
      </Container>
    </Link>
  )
}

export default CartButton
