import React from 'react'
import styled from 'styled-components'
import { WideContainer } from 'src/features/layout'
import { Link as BaseLink } from 'gatsby'

const Container = styled.nav`
  width: 100%;
  background-color: ${p => p.theme.darkBg};
  color: ${p => p.theme.primaryReverse};
`

const Inner = styled(WideContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const List = styled.ul`
  display: flex;
  margin: 0 0 0 -1rem;
  padding-left: 0;
  list-style: none;
`

const NavLink = styled(BaseLink)`
  display: block;
  padding: 0.75rem 1rem;
  outline: 0;
  font-family: 'Raleway', sans-serif;
  text-decoration: none;
  color: ${p => p.theme.primaryReverse};
`

const NavItem = styled(({ to, className, children }) => (
  <li className={className}>
    <NavLink to={to}>{children}</NavLink>
  </li>
))``

const Tagline = styled.div`
  font-style: italic;
  span {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 599.98px) {
    display: none;
  }
`

const Nav = () => (
  <Container>
    <Inner>
      <List>
        <NavItem to="/about">About</NavItem>
        <NavItem to="/products">Products</NavItem>
        <NavItem to="/blog">Blog</NavItem>
        <NavItem to="/contact">Contact</NavItem>
      </List>
      <Tagline>
        <span>Theology</span> through <span>story.</span>
      </Tagline>
    </Inner>
  </Container>
)

export default Nav
