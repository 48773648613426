import React from 'react'
import styled from 'styled-components'
import { Header, Footer } from 'src/features/nav'
import { WideContainer } from 'src/features/layout'

const Main = styled.main`
  margin: 30px 0px;
`

const StandardPage = ({ children }) => (
  <>
    <Header />
    <Main>
      <WideContainer>
        <main>{children}</main>
      </WideContainer>
    </Main>
    <Footer />
  </>
)

export default StandardPage
