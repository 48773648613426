import styled from 'styled-components'

const Section = styled.div`
  margin: 2rem 0;
  @media only screen and (min-width: 600px) {
    margin: 2.5rem 0;
  }
  @media only screen and (min-width: 800px) {
    margin: 3rem 0;
  }
  @media only screen and (min-width: 1000px) {
    margin: 3.5rem 0;
  }
`

export default Section
