import React from 'react'
import { useStaticQuery, graphql, Link as BaseLink } from 'gatsby'
import styled from 'styled-components'
import { WideContainer } from 'src/features/layout'
import CartButton from 'src/features/cart/components/CartButton'
import logo from '../assets/logo.svg'

const Container = styled.div`
  background-color: ${p => p.theme.headerBg};
  padding: 0.75rem 0;
  @media only screen and (min-width: 800px) {
    padding: 1rem 0;
  }
  @media only screen and (min-width: 1000px) {
    padding: 1.25rem 0;
  }
`

const Inner = styled(WideContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Link = styled(BaseLink)`
  outline: 0;
`

const Logo = styled.img`
  display: block;
  width: auto;
  height: 3rem;
  @media only screen and (min-width: 450px) {
    height: 3.25rem;
  }
  @media only screen and (min-width: 600px) {
    height: 3.5rem;
  }
  @media only screen and (min-width: 800px) {
    height: 3.75rem;
  }
`

const LogoBar = () => {
  const { title } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `).site.siteMetadata

  return (
    <Container>
      <Inner>
        <Link to="/">
          <Logo src={logo} alt={title} />
        </Link>
        <CartButton />
      </Inner>
    </Container>
  )
}

export default LogoBar
