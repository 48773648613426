import React from 'react'
import styled from 'styled-components'
import LogoBar from './LogoBar'
import Nav from './Nav'

const Header = styled(({ className }) => (
  <header className={className}>
    <LogoBar />
    <Nav />
  </header>
))``

export default Header
