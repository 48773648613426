import styled from 'styled-components'

const WideContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
  @media only screen and (min-width: 800px) {
    padding: 0 1.25rem;
  }
`

export default WideContainer
